import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { Link as CustomLink } from 'components/base/Link';
import { ImageWrapper } from 'components/wrapper/imageWrapper';
import { LinkType } from 'lib/enums/LinkType';
import { Contact } from 'models';
import { useRouter } from 'next/router';
import { withTranslations } from 'store/translations';

const Person = ({
  frontifyImage,
  msImage,
  // department,
  displayName,
  hasContact = false,
  id,
  jobTitle,
  city,
  phoneNumber,
  alternativeText,
}: Omit<Contact['elements'], 'key'> & { hasContact: boolean }) => {
  const { common, multiple, url } = withTranslations();
  const router = useRouter();
  //const jobDesc = jobTitle.value.split(' ').length;
  return (
    <Flex
      flexDirection='column'
      color='mountain.base'
      justifyContent='space-between'
      alignItems={{ base: 'center', md: 'flex-start' }}
      textAlign={{ base: 'center', md: 'left' }}
    >
      <Box order={0}>
        <ImageWrapper
          imageFrontify={frontifyImage.value}
          msImage={msImage.value}
          alt={alternativeText.value == '' ? displayName.value : alternativeText.value}
          imageSx={{
            mb: { base: 'xs', lg: 's' },
            w: { base: '160px', lg: '200px' },
            h: { base: '160px', lg: '200px' },
          }}
        />
      </Box>
      <Box height='80px'>
        <Text as='h3' mb={{ base: 'xxs', lg: '0' }} textStyle='desktop.body.medium.l'>
          {displayName.value}
        </Text>
        <Text textStyle='desktop.body.l'>{jobTitle.value}</Text>
        <Text mb='xs' textStyle='desktop.body.l'></Text>
      </Box>
      <Box>
        {phoneNumber.value && (
          <Link display='block' href={`tel:${phoneNumber.value}`} textStyle='desktop.body.l'>
            {phoneNumber.value}
          </Link>
        )}
        {!phoneNumber.value && (
          <Link display='block' style={{ visibility: 'hidden' }} href='javascript:void(0);' textStyle='desktop.body.l'>
            {'N/A'}
          </Link>
        )}
        <Text mb='xs' textStyle='desktop.body.l'>
          {city.value}
        </Text>
      </Box>
      {/* TODO: Not implemented at the moment, we will need to figure out how would this work in the future */}
      {/* <Text mb={{ base: 's', lg: 'xs' }} textStyle='desktop.body.s'>
        Local time: {person.localTime}
      </Text> */}
      {hasContact && (
        <Box>
          <CustomLink
            href={`/${multiple('contactUsUrl')}/${url('peopleUrl')}/${id.value}`}
            iconName='chevronRightForLink'
            type={LinkType.SecondaryButton}
            locale={router.locale}
          >
            {common('getInTouch')}
          </CustomLink>
        </Box>
      )}
    </Flex>
  );
};

export default Person;
