import {
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { ProdClient } from 'client/client';
import { PaginationNew } from 'components/base/PaginationNew';
import { Contact as ContactModel } from 'models';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { usePaginatedPeople } from 'store/paginated-people';
import { withTranslations } from 'store/translations';
import { alphabeticalSort } from 'utils/alphabeticalSort';
import { isDefined } from 'utils/isDefined';

import Person from './Person';

interface FetchProps {
  page: number;
  city?: string;
  country?: string;
  department?: string;
  market?: string;
  names?: string[];
}

const People = () => {
  const {
    cities,
    selectedCity,
    countries,
    setSelectedCity,
    selectedCountry,
    setSelectedCountry,
    markets,
    selectedMarket,
    setSelectedMarket,
    // departments,
    // selectedDepartment,
    // setSelectedDepartment,
    setCities,
    pagination,
    people,
    setPeople,
    setPagination,
    names,
  } = usePaginatedPeople();
  const { common, aria, location, market } = withTranslations();
  // const translations = withTranslations();
  const [filteredCities, setFilteredCities] = useState<string[]>();
  const [filteredMarkets, setFilteredMarkets] = useState<string[]>();
  const [filteredCountries, setFilteredCountries] = useState<string[]>();
  const [shouldShowHelperText, setShouldShowHelperText] = useState(false);
  const [filterByNameValue, setFilterByNameValue] = useState('');
  const [filteredPeople, setFilteredPeople] = useState<string[]>();
  const [fetchTimeout, setFetchTimeout] = useState<NodeJS.Timeout>();
  // const [countryObj, setCountryObj] = useState<any>();
  const router = useRouter();
  const { setDepartments, setCountries, setMarkets } = usePaginatedPeople();
  const tempObj = {};
  const tempObjmarket = {};
  const [local, setLocal] = useState('en');

  useEffect(() => {
    const localeMap = {
      'en-gb': 'United Kingdom',
      'da-dk': 'Denmark',
      'fi-fi': 'Finland',
      'no-no': 'Norway',
      'de-de': 'Germany',
      'sv-se': 'Sweden',
    };

    const country = router.locale && localeMap[router.locale];
    if (router.locale === 'en' || router.locale === 'en-apac' || router.locale === 'en-us') {
      fetchPeople({
        page: 1,
        city: selectedCity,
        names: filterByNameValue.length > 0 ? filteredPeople : [''],
        country: selectedCountry,
        market: selectedMarket,
      });
    } else if (country) {
      setLocal(country);
      setSelectedCountry(local);
      let cityStrs = selectedCity;
      setSelectedCity('');
      cityStrs = '';
      fetchPeople({
        page: 1,
        country: local,
        market: selectedMarket,
        names: filterByNameValue.length > 0 ? filteredPeople : [''],
        city: cityStrs,
      });
    }
    return () => {
      setLocal('');
      setSelectedCountry('');
      setSelectedCity('');
    };
  }, [local, router.locale]);

  // const capitalizeFirstLetter = (string) => {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };
  // useEffect(() => {
  //   if (translations.isReady) {
  //     const tempObj = {};
  //     countries?.map((country) => {
  //       const localise = location(country.toLocaleLowerCase().replaceAll(' ', '_'));
  //       const key = localise;
  //       // let tempArr:any = []
  //       tempObj[key] = country;
  //       // tempArr.push(obj)
  //     });
  //     setCountryObj(tempObj);
  //   }

  //   // usePaginatedPeople()
  // }, [router.isReady, translations.locale, translations.isReady]);
  // const sortedDepartments = departments
  //   ? departments.sort((a, b) => {
  //       const aStr = a
  //         .replace(/\b\d{4,}\b/g, '')
  //         .replace(/^\s+/, '')
  //         .replace(/^[ -]+|[ -]+$/g, '');
  //       const bStr = b
  //         .replace(/\b\d{4,}\b/g, '')
  //         .replace(/^\s+/, '')
  //         .replace(/^[ -]+|[ -]+$/g, '');

  //       return aStr.localeCompare(bStr);
  //     })
  //   : [];
  const languageCodename = router.locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  const limit = 1000;
  let skip = 0;
  let filterData = [] as ContactModel[];
  useEffect(() => {
    const getDropdown = async (languageCodename: string) => {
      const response = await ProdClient.items<ContactModel>()
        .type('contact')
        .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
        .languageParameter(languageCodename)
        .includeTotalCountParameter()
        .elementsParameter(['department', 'city', 'country', 'market'])
        .limitParameter(limit)
        .skipParameter(skip)
        .toPromise();
      if (!response.data.pagination.nextPage) {
        skip = 0;
        return;
      }
      filterData = filterData.concat(...response.data.items);
      // eslint-disable-next-line no-console
      //   console.log(filterData);
      const filterOptions = filterData
        .reduce<ContactModel[]>((acc, item) => {
          if (item.elements.city.value) {
            item.elements.city.value = item.elements.city.value.split(',')[0].trim();
          }
          if (item.elements.country.value) {
            item.elements.country.value = item.elements.country.value.split(',')[0].trim();
          }
          if (item.elements.market.value) {
            item.elements.market.value = item.elements.market.value.split(',')[0].trim();
          }
          acc.push(item);
          return acc;
        }, [])
        .map((item) => item.elements);
      const dept = new Array<string>();
      const country = new Array<string>();
      const city = new Array<string>();
      const market = new Array<string>();

      filterOptions.forEach(
        (item) => !dept.includes(item.department.value.trim()) && dept.push(item.department.value.trim()),
      );
      filterOptions.forEach(
        (item) => !country.includes(item.country.value.trim()) && country.push(item.country.value.trim()),
      );
      filterOptions.forEach((item) => !city.includes(item.city.value.trim()) && city.push(item.city.value.trim()));
      filterOptions.forEach(
        (item) => !market.includes(item.market.value.trim()) && market.push(item.market.value.trim()),
      );

      const filteredCountryArray =
        country &&
        country.reduce((unique: string[], item: string) => {
          if (item && item.trim() !== '' && !unique.some((val) => val.toLowerCase() === item.toLowerCase())) {
            item != 'USA' && unique.push(item);
          }
          return unique;
        }, []);
      const filteredCityArray =
        city &&
        city.reduce((unique: string[], item: string) => {
          if (item && item.trim() !== '' && !unique.some((val) => val.toLowerCase() === item.toLowerCase())) {
            unique.push(item);
          }
          return unique;
        }, []);
      const filteredMarketArray =
        market &&
        market.reduce((unique: string[], item: string) => {
          if (item && item.trim() !== '' && !unique.some((val) => val.toLowerCase() === item.toLowerCase())) {
            unique.push(item);
          }
          return unique;
        }, []);

      setDepartments(dept);
      setCountries(filteredCountryArray ? filteredCountryArray : country);
      setCities(filteredCityArray ? filteredCityArray : city);
      setMarkets(filteredMarketArray ? filteredMarketArray : market);
      skip += limit;
      await getDropdown(languageCodename);
    };

    getDropdown(languageCodename);
    // console.log('selectedMarket ',selectedMarket)
    fetchPeople({
      page: 1,
      city: selectedCity,
      names: filterByNameValue.length > 0 ? filteredPeople : [''],
      country: selectedCountry,
      market: selectedMarket,
    });
  }, [router.locale]);

  const numSelectedFilters = useMemo(() => {
    let count = 0;

    Boolean(selectedCity) && count++;
    Boolean(filterByNameValue) && count++;
    Boolean(selectedCountry) && count++;
    Boolean(selectedMarket) && count++;

    return count;
  }, [selectedCity, filterByNameValue, selectedCountry, selectedMarket]);

  const fetchPeople = async ({ page, city, department, names, country, market }: FetchProps) => {
    try {
      const peopleData = await fetch(
        `/api/get-people?pageNum=${page}&locale=${router.locale}${
          names
            ? `&names=${encodeURIComponent(
                names
                  ?.filter((item, pos) => {
                    return names.indexOf(item) == pos;
                  })
                  .toString(),
              )}`
            : ''
        }${city ? `&city=${encodeURIComponent(city)}` : ''}${
          department ? `&department=${encodeURIComponent(department)}` : ''
        }${country ? `&country=${encodeURIComponent(country)}` : ''}${
          market ? `&market=${encodeURIComponent(market)}` : ''
        }`,
        {
          method: 'POST',
        },
      ).then((res) => res.json());
      const people = peopleData.items.items
        .filter(isDefined)
        .map((item) => item.elements)
        .sort((a, b) => alphabeticalSort(a.displayName.value, b.displayName.value, 0));

      // Creating a temporary array to filter and store departments so there's no duplicates
      let uniqueDepartments: string[] = [];
      peopleData.departments &&
        peopleData.departments.items.forEach((item) => {
          // If there's no duplicate then push it to the temporary array
          if (!uniqueDepartments.includes(item.elements.department.value)) {
            uniqueDepartments.push(item.elements.department.value);
          }
        });
      let uniqueCities: string[] = [];
      peopleData.cities &&
        peopleData.cities.items.forEach((item) => {
          if (!uniqueCities.includes(item.elements.city.value)) {
            uniqueCities.push(item.elements.city.value);
          }
        });
      let uniqueCountries: string[] = [];
      peopleData.countries &&
        peopleData.countries.items.forEach((item) => {
          if (!uniqueCountries.includes(item.elements.country.value)) {
            uniqueCountries.push(item.elements.country.value);
          }
        });
      let uniqueMarkets: string[] = [];
      peopleData.markets &&
        peopleData.markets.items.forEach((item) => {
          if (!uniqueMarkets.includes(item.elements.market.value)) {
            uniqueMarkets.push(item.elements.market.value);
          }
        });

      uniqueCities = uniqueCities.sort();
      uniqueMarkets = uniqueMarkets.sort();
      uniqueCountries = uniqueCountries.sort();
      // eslint-disable-next-line no-console
      //  console.log('uniqueCountries', uniqueCountries);
      // Sorting the departments based on formatting for title
      uniqueDepartments = uniqueDepartments.sort((a, b) => {
        const aStr = a
          .replace(/\b\d{4,}\b/g, '')
          .replace(/^\s+/, '')
          .replace(/^[ -]+|[ -]+$/g, '');
        const bStr = b
          .replace(/\b\d{4,}\b/g, '')
          .replace(/^\s+/, '')
          .replace(/^[ -]+|[ -]+$/g, '');

        return aStr.localeCompare(bStr);
      });
      // Show the updated list of departments
      // If there's no query for city these will not be fetched and the list of departments will fall back to the full list
      setFilteredCities(uniqueCities);
      setFilteredMarkets(uniqueMarkets);
      setFilteredCountries(uniqueCountries);
      setPeople(people);
      setPagination(peopleData.items.pagination);
    } catch (err: any) {
      throw new Error(err.message);
    }
  };

  // const fetchCities = async (country: string) => {
  //   try {
  //     // Make an API call to fetch cities based on the selected country
  //     const response = await fetch(`/api/get-cities?country=${encodeURIComponent(country)}`);
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch cities');
  //     }
  //     const data = await response.json();
  //     // Extract the cities from the response data
  //     const citiesData = data.cities;
  //     // Update the cities state with the fetched cities
  //     setFilteredCities(citiesData);
  //   } catch (error) {
  //     console.error('Error fetching cities:', error);
  //   }
  // };
  return (
    <Container pb={{ base: 's', lg: 'm' }} pt='s'>
      <Flex
        align='center'
        direction={{ base: 'column', lg: 'row' }}
        gap={{ base: 'xs', lg: '0' }}
        mb={{ base: 's', lg: 'm' }}
      >
        <Flex
          align='center'
          direction={{ base: 'column', lg: 'row' }}
          gap={{ base: 'xs', lg: '0' }}
          w={{ base: '100%', lg: 'auto' }}
        >
          <FormControl mr={{ base: '0', lg: 's' }}>
            <FormLabel mb='3xs'>
              <Text color='mountain.base' textStyle='mobile.body.xs'>
                {common('searchByName')}
              </Text>
            </FormLabel>
            <Input
              bg='gray.98'
              borderColor='mountain.20'
              placeholder={aria('search')}
              value={filterByNameValue}
              onChange={(e) => {
                setFilterByNameValue(e.target.value);
                clearTimeout(fetchTimeout);
                if (e.target.value.length === 0) {
                  setShouldShowHelperText(false);
                  fetchPeople({ page: 1, city: selectedCity, market: selectedMarket });
                } else if (e.target.value.length > 2) {
                  setShouldShowHelperText(false);
                  const filteredNames = names?.filter((name) =>
                    name.toLowerCase().includes(e.target.value.toLowerCase()),
                  );
                  setFilteredPeople(filteredNames);
                  setFetchTimeout(
                    setTimeout(() => {
                      fetchPeople({
                        page: 1,
                        city: selectedCity,
                        market: selectedMarket,
                        // Need to send some sort of text that will not be in the name field, so that returning result is null
                        names: filteredNames?.length ? filteredNames : ['{NULL RETURN}'],
                      });
                    }, 1000),
                  );
                } else setShouldShowHelperText(true);
              }}
            />
            <FormHelperText
              display={shouldShowHelperText ? '' : 'none'}
              position={{ base: 'relative', lg: 'absolute' }}
            >
              {common('searchByNameInfo')}
            </FormHelperText>
          </FormControl>
          {markets?.sort(Intl.Collator().compare) && (
            <FormControl mr={{ base: '0', lg: 's' }}>
              <FormLabel mb='3xs'>
                <Text color='mountain.base' textStyle='mobile.body.xs'>
                  {common('market')}
                </Text>
              </FormLabel>
              <Select
                bg='gray.98'
                borderColor='mountain.20'
                borderRadius='0'
                w={{ base: '100%', lg: '200px' }}
                value={market(selectedMarket)}
                onChange={(e) => {
                  setSelectedMarket(e.target.value);
                  let countryStr = selectedCountry;
                  let cityStr = selectedCity;
                  // if (e.target.value == '') {
                  //   setSelectedCity('');
                  //   setSelectedCountry('');
                  //   countryStr = '';
                  //   cityStr = '';
                  // } else if (e.target.value != '') {
                  //   countryStr = '';
                  //   cityStr = '';
                  // }
                  setSelectedCity('');
                  setSelectedCountry('');
                  countryStr = '';
                  cityStr = '';
                  fetchPeople({
                    page: 1,
                    market: e.target.value ? e.target.value : undefined,
                    names: filterByNameValue.length > 0 ? filteredPeople : [''],
                    country: countryStr,
                    city: cityStr,
                  });
                }}
              >
                <option value=''>{common('all')}</option>
                {[
                  ...(filteredMarkets?.length ? filteredMarkets : markets)
                    .map((option) => market(option.toLocaleLowerCase().replaceAll(' ', '_')))
                    .sort(Intl.Collator().compare)
                    .map((localizedOption) => {
                      // localizedOption === 'Usa' ? localeOption = 'USA' : localeOption = localizedOption
                      Object.keys(tempObjmarket).length == 0 &&
                        markets?.map((marketValue) => {
                          //  const localise = market.toLocaleLowerCase().replaceAll(' ', '_');
                          const localise = market(marketValue.toLocaleLowerCase().replaceAll(' ', '_'));

                          const key = localise;
                          tempObjmarket[key] = marketValue;
                          // tempArr.push(obj)
                        });
                      return (
                        localizedOption.length > 0 &&
                        market(localizedOption).length > 0 && (
                          <option key={localizedOption} value={tempObjmarket[localizedOption]}>
                            {/* {capitalizeFirstLetter(
                              market(localizedOption.toLocaleLowerCase().replaceAll(' ', '_')),
                            ).replaceAll('_', ' ')} */}
                            {languageCodename === 'en' ? localizedOption : market(localizedOption)}
                          </option>
                        )
                      );
                    }),
                ]}
              </Select>
            </FormControl>
          )}
          {countries && (
            <FormControl mr={{ base: '0', lg: 's' }}>
              <FormLabel mb='3xs'>
                <Text color='mountain.base' textStyle='mobile.body.xs'>
                  {common('country')}
                </Text>
              </FormLabel>
              <Select
                id='country-dropdown'
                bg='gray.98'
                borderColor='mountain.20'
                borderRadius='0'
                w={{ base: '100%', lg: '200px' }}
                value={location(selectedCountry)}
                onChange={(e) => {
                  setSelectedCountry(e.target.value);

                  // setSelectedCountry(e.target.value === 'USA' ? 'United States of America' : e.target.value);
                  // if (e.target.value == '') {
                  //   setSelectedCity('');
                  // }
                  let cityStrs = selectedCity;
                  setSelectedCity('');
                  cityStrs = '';
                  fetchPeople({
                    page: 1,
                    country: e.target.value
                      ? e.target.value == 'USA'
                        ? 'United States of America'
                        : e.target.value
                      : undefined,
                    market: selectedMarket,
                    names: filterByNameValue.length > 0 ? filteredPeople : [''],
                    city: cityStrs,
                  });
                  //
                  //  setSelectedCity('')
                  // Fetch cities based on the selected country
                  // if (e.target.value) {
                  //  fetchCities(e.target.value);
                  // }
                }}
              >
                <option value=''>{common('all')}</option>
                {[
                  ...(filteredCountries?.length ? filteredCountries : countries)
                    .map((option) => location(option.toLocaleLowerCase().replaceAll(' ', '_')))
                    .sort(Intl.Collator().compare)
                    .map((localizedOption) => {
                      // localizedOption === 'Usa' ? localeOption = 'USA' : localeOption = localizedOption
                      Object.keys(tempObj).length == 0 &&
                        countries?.map((country) => {
                          const localise = location(country.toLocaleLowerCase().replaceAll(' ', '_'));
                          const key = localise;
                          tempObj[key] = country;
                          // tempArr.push(obj)
                        });
                      return (
                        localizedOption.length > 0 &&
                        location(localizedOption).length > 0 && (
                          <option key={localizedOption} value={tempObj[localizedOption]}>
                            {languageCodename === 'en' ? localizedOption : location(localizedOption)}
                          </option>
                        )
                      );
                    }),
                ]}
              </Select>
            </FormControl>
          )}
          {cities?.sort(Intl.Collator().compare) && (
            <FormControl mr={{ base: '0', lg: 's' }}>
              <FormLabel mb='3xs'>
                <Text color='mountain.base' textStyle='mobile.body.xs'>
                  {common('location')}
                </Text>
              </FormLabel>
              <Select
                bg='gray.98'
                borderColor='mountain.20'
                borderRadius='0'
                w={{ base: '100%', lg: '200px' }}
                value={selectedCity}
                onChange={(e) => {
                  setSelectedCity(e.target.value);
                  fetchPeople({
                    page: 1,
                    city: e.target.value ? e.target.value : undefined,
                    names: filterByNameValue.length > 0 ? filteredPeople : [''],
                    country: selectedCountry === 'USA' ? 'United States of America' : selectedCountry,
                    market: selectedMarket,
                  });
                }}
              >
                <option value=''>{common('all')}</option>
                {[
                  ...new Set(
                    filteredCities?.length
                      ? filteredCities.filter(
                          (city, index) =>
                            filteredCities.findIndex((c) => c.toLowerCase() === city.toLowerCase()) === index &&
                            city.toLowerCase() !== 'fort collins',
                        )
                      : cities.filter(
                          (city, index) =>
                            cities.findIndex((c) => c.toLowerCase() === city.toLowerCase()) === index &&
                            city.toLowerCase() !== 'fort collins',
                        ),
                  ),
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
        </Flex>
      </Flex>
      <Flex
        align='center'
        direction={{ base: 'column', lg: 'row' }}
        gap={{ base: 'xs', lg: '0' }}
        mb={{ base: 's', lg: 'xl' }}
      >
        {pagination && (
          <Text
            visibility={pagination.count ? 'visible' : 'hidden'}
            alignSelf={{ base: 'start', lg: 'none' }}
            color='mountain.base'
            flex='1'
            order={{ base: '2', lg: '1' }}
            textStyle='mobile.body.medium.s'
            dangerouslySetInnerHTML={{
              __html: common('showingResults', {
                itemsShown: `${pagination.skip + 1} - ${pagination.skip + pagination.count}`,
                total: pagination.totalCount,
              }),
            }}
          />
        )}
        <Button
          alignSelf={{ base: 'end', lg: 'none' }}
          color='cyan.web'
          disabled={numSelectedFilters === 0}
          order={{ base: '1', lg: '2' }}
          textStyle='mobile.body.medium.s'
          variant='ghost'
          borderRadius='4px'
          onClick={() => {
            setSelectedCity('');
            setSelectedCountry('');
            setSelectedMarket('');
            setFilteredPeople([]);
            setFilterByNameValue('');
            fetchPeople({ page: 1, city: '', market: '' });
          }}
        >
          {common('clearFilters')} ({numSelectedFilters})
        </Button>
      </Flex>
      {people?.length ? (
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacingX={{ base: 0, md: 'xs' }}
          spacingY={{ base: 's', lg: 'xl' }}
          pb={{ lg: 'lr' }}
        >
          {people.map((person) => {
            if (person.displayName.value !== '') return <Person hasContact {...person} key={person.id.value} />;
          })}
        </SimpleGrid>
      ) : (
        <Center>
          <Text color='mountain.base' textStyle={{ base: 'mobile.body.medium.s', lg: 'desktop.body.medium.l' }}>
            {common('noResultsFound')}
            {/* {filterByNameValue.length == 0 ? common('noResultsFound') : `The name with ${filterByNameValue} is not found in ${router.locale}`} */}
          </Text>
        </Center>
      )}
      {pagination && (
        <Flex mt={{ base: 's', lg: 'xl' }} justifyContent='center'>
          <PaginationNew
            total={pagination?.totalCount || 0}
            perPage={12}
            current={pagination.skip / pagination.limit + 1}
            onNavigate={(page) =>
              fetchPeople({
                page,
                city: selectedCity,
                names: filterByNameValue.length > 0 ? filteredPeople : [''],
                country: selectedCountry,
                market: selectedMarket,
              })
            }
          />
        </Flex>
      )}
    </Container>
  );
};

export default People;
